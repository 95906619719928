<template>
  <div>
    <div>{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    value() {
      return this.field.Value ?? "";
    },
  },
};
</script>
